import {HttpClient} from '@angular/common/http';
import {Injectable, inject} from '@angular/core';
import {CandidateResponse} from '@core/models/candidate/candidate-response';
import {PartyDetailResponse} from '@core/models/candidate/party-detail-response';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {CandidateDetailResponse} from '@core/models/candidate/candidate-detail-response';

@Injectable({
    providedIn: 'root',
})
export class CandidateService {
    http = inject(HttpClient);

    private candidatesBaseUrl: string;
    private candidatesPartyDetailUrl: string;

    private callTimer: number = 0;
    constructor() {
        this.candidatesBaseUrl = environment.apiUrl + '/candidates';
        this.candidatesPartyDetailUrl = this.candidatesBaseUrl + '/party/detail';
        //his.userSubject = new BehaviorSubject<User>({ user: {}, token: '', refreshToken: ''});
    }

    getCandidates(): Observable<CandidateResponse> {
        const reqParams = {
            pageIndex: 0,
            type: 0,
        };

        const options = {params: reqParams};
        return this.http.get<CandidateResponse>(this.candidatesBaseUrl, options);
    }

    getFollowings(): Observable<CandidateResponse> {
        const reqParams = {
            pageIndex: 0,
            type: 1,
        };

        const options = {params: reqParams};
        return this.http.get<CandidateResponse>(this.candidatesBaseUrl, options);
    }

    getCandidateDetail(candidateId: number): Observable<CandidateDetailResponse> {
        const reqParams = {
            candidateId,
        };
        const options = {params: reqParams};
        return this.http.get<CandidateDetailResponse>(this.candidatesBaseUrl + '/detail', options);
    }

    getPartyDetail(roomId: number): Observable<PartyDetailResponse> {
        const reqParams = {
            roomId: roomId,
        };

        const options = {params: reqParams};
        return this.http.get<PartyDetailResponse>(this.candidatesPartyDetailUrl, options);
    }

    getCallTimer(): number {
        return this.callTimer;
    }

    setCallTimer(timer: number) {
        this.callTimer = timer;
    }
}
